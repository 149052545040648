import React, { useState, useCallback, useMemo } from 'react';
import { intersectionBy } from 'lodash';
import { getProxyOptionsForFund, type ProxyOption, type ProxyTypeEnum, type FundReturnsRange } from 'venn-api';
import { Notifications, NotificationType, RadioGroup, ShimmerBlock } from 'venn-ui-kit';
import {
  createProxyOptionsKey,
  getFormattedProxyType,
  logExceptionIntoSentry,
  useQueries,
  withSuspense,
} from 'venn-utils';

import { getCategoryProxyTypeMetadata, sortCategoryOptions, type SelectedProxy } from './utils';
import type { FundToBulkProxy } from '../types';
import { BulkProxySearch } from './components/BulkProxySearch';

interface BulkCategoryOptionProps {
  investments: FundToBulkProxy[];
  proxyType?: ProxyTypeEnum;
  selectedProxy: SelectedProxy | null;
  numLags?: number;
  setSelectedProxy: (selected: SelectedProxy | null) => void;
  rawInvestmentReturnsRanges: FundReturnsRange[];
  selectedProxyType: ProxyTypeEnum | undefined;
}

interface RadioOption {
  element: JSX.Element;
  value: string;
  index: number;
  disabled?: boolean;
}

const ADDITIONAL_OPTIONS = 2; // the radio group has two additional options we're manually including: search input and a text category

const TextCategory = ({ proxyType }: { proxyType?: ProxyTypeEnum }) => {
  if (!proxyType) {
    return null;
  }

  const proxyTypeMetadata = getCategoryProxyTypeMetadata(proxyType);
  const lowerCaseProxyTypeName = getFormattedProxyType(proxyType).toLowerCase();

  return (
    <div className="border border-solid border-venn-grey-30 border-b-0 p-4 flex flex-col">
      <span className="font-bold leading-normal">Or select a category below</span>
      <span className="text-venn-grey-80">
        Categories represent public indices that can be used for {lowerCaseProxyTypeName}.{' '}
        <a
          className="font-bold text-venn-dark-blue"
          target="_blank"
          rel="noopener noreferrer"
          href={proxyTypeMetadata.helpLink}
        >
          Learn more
        </a>
      </span>
    </div>
  );
};

const RadioCategory = ({ label, description }: { label: string; description: string }) => {
  return (
    <div className="flex flex-col ml-0.5">
      <span className="leading-normal">{label}</span>
      <span className="text-[12px] text-venn-grey-80">{description}</span>
    </div>
  );
};

const ShimmerFallback = () => {
  return (
    <div className="mt-3">
      {Array.from({ length: 8 }, (_, index) => (
        <ShimmerBlock
          height={70}
          key={`shimmer-${index}`}
          className="w-full border border-solid border-venn-grey-30 mb-0"
        />
      ))}
    </div>
  );
};

// TODO(VENN-28194): Ensure numLags is passed down properly
const InternalBulkCategoryOptions = ({
  investments,
  proxyType,
  numLags = 0,
  selectedProxy,
  setSelectedProxy,
  selectedProxyType,
  rawInvestmentReturnsRanges,
}: BulkCategoryOptionProps) => {
  const sortedProxyOptions = useProxyOptions(investments, proxyType, numLags);
  const options: RadioOption[] = useMemo(
    () =>
      sortedProxyOptions.map((option, index) => ({
        element: <RadioCategory label={option.category.displayName} description={option.category.indexName} />,
        value: option.category.indexId,
        index,
        disabled: !option.proxiedCumulativeReturn,
      })),
    [sortedProxyOptions],
  );

  const isCategoryProxy = useIsCategoryProxy(selectedProxy, options);

  const [customSelectedProxy, setCustomSelectedProxy] = useState<SelectedProxy | null>(
    !isCategoryProxy ? selectedProxy : null,
  );

  const [selectedIndex, setSelectedIndex] = useState<number>(() =>
    !!selectedProxy && isCategoryProxy
      ? options.findIndex((option) => option.value === selectedProxy.id) + ADDITIONAL_OPTIONS // +2 for the search and text options
      : 0,
  );

  const onSelectCustomProxy = useCallback(
    (customProxy: SelectedProxy) => {
      setSelectedProxy(customProxy);
      setCustomSelectedProxy(customProxy);
    },
    [setSelectedProxy],
  );

  const combinedOptions = useMemo(() => {
    const allCategoryIds = sortedProxyOptions.map((option) => option.category.indexId);

    const searchOption = {
      element: (
        <BulkProxySearch
          selectedProxy={customSelectedProxy}
          setSelectedProxy={onSelectCustomProxy}
          rawInvestmentReturnsRanges={rawInvestmentReturnsRanges}
          selectedProxyType={selectedProxyType}
          investments={investments}
          disabled={selectedIndex !== 0}
          excludedInvestmentIds={allCategoryIds}
        />
      ),
      value: 'custom',
      index: 0,
    };

    const textOption = {
      element: <TextCategory proxyType={proxyType} />,
      value: 'text',
      index: 1,
      showRadio: false,
    };
    return [searchOption, textOption, ...options];
  }, [
    selectedIndex,
    sortedProxyOptions,
    options,
    proxyType,
    rawInvestmentReturnsRanges,
    selectedProxyType,
    customSelectedProxy,
    investments,
    onSelectCustomProxy,
  ]);

  const onCategoryChange = useCallback(
    (value: string) => {
      const selectedOptionIndex = combinedOptions.findIndex((option) => option.value === value);
      setSelectedIndex(selectedOptionIndex);

      if (value === 'custom') {
        setSelectedProxy(customSelectedProxy);
      } else {
        const selectedOption = sortedProxyOptions.find((option) => option.category.indexId === value);
        selectedOption &&
          setSelectedProxy({ name: selectedOption.category.indexName, id: selectedOption.category.indexId });
      }
    },
    [combinedOptions, customSelectedProxy, sortedProxyOptions, setSelectedProxy],
  );

  return (
    <div className="flex flex-col mt-3">
      <RadioGroup
        groupName="bulk-proxy-group"
        options={combinedOptions}
        onChange={onCategoryChange}
        className="border border-solid border-venn-grey-30 border-b-0 p-4 hover:bg-venn-grey-10 last:border-b mr-0"
        defaultOption={combinedOptions[selectedIndex ?? 0].value}
      />
    </div>
  );
};

const useProxyOptions = (
  investments: FundToBulkProxy[],
  proxyType?: ProxyTypeEnum,
  numLags?: number,
): ProxyOption[] => {
  const queries = useMemo(() => {
    if (!investments.length || !proxyType) {
      return [];
    }

    return investments.map(({ id }) => ({
      queryKey: createProxyOptionsKey(id, proxyType, numLags ?? 0),
      queryFn: () => getProxyOptionsForFund(id, proxyType, numLags ?? 0).then((response) => response.content),
      onError: (error) => {
        Notifications.notify('Unable to load suggested proxy categories', NotificationType.ERROR);
        logExceptionIntoSentry(error);
      },
      suspense: true,
    }));
  }, [investments, proxyType, numLags]);

  const results = useQueries({ queries });

  const sortedProxyOptions = useMemo(() => {
    const proxyOptions = results.reduce<ProxyOption[]>((acc, result) => {
      if (result.data) {
        if (acc.length === 0) {
          return result.data;
        }
        return intersectionBy(acc, result.data, 'category.indexId');
      }

      return acc;
    }, []);

    return proxyOptions.sort(sortCategoryOptions);
  }, [results]);

  return sortedProxyOptions;
};

const useIsCategoryProxy = (initialProxy: SelectedProxy | null, options: RadioOption[]): boolean => {
  return options.some((option) => option.value === initialProxy?.id);
};

export const BulkCategoryOptions = withSuspense(<ShimmerFallback />, React.memo(InternalBulkCategoryOptions));
