import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { type ProxyTypeEnum, bulkSetProxy, type DetailedProxyMetadata } from 'venn-api';
import {
  Icon,
  Notifications,
  NotificationType,
  PROXY_FAQ_HREF,
  Link as LinkStyle,
  Loading,
  DESMOOTHING_HELP_HREF,
} from 'venn-ui-kit';
import ProxyTypeOptions from './ProxyTypeOptions';
import { useHasFF } from 'venn-utils';
import { typeSupportsCategoryPicker, typeSupportsDesmooth, type SelectedProxy } from './utils';
import { useBulkProxyErrorMessage } from './useProxyErrorMessage';
import { useInvestmentsReturnsRanges, useProxyReturnsRange } from './useInvestmentsReturnsRanges';
import { partition } from 'lodash';
import { ExtrapolationToggle, useExtrapolateToggle } from './components/ExtrapolationToggle';
import { ProxySummaryTable } from './ProxySummaryTable';
import SidePanelOverlay from '../../../side-panel-overlay/SidePanelOverlay';
import type { FundToBulkProxy } from '../types';
import { BulkCategoryOptions } from './BulkCategoryOptions';
import { SingleInvestmentInfo } from './components/SingleInvestmentInfo';
import { BulkProxySearch } from './components/BulkProxySearch';

interface BulkProxyPickerProps {
  investments: FundToBulkProxy[];
  proxyDataByFund: Record<string, DetailedProxyMetadata | undefined>;
  onProxyChange: (fundId: string[]) => void | Promise<void>;
  onProxyFailedToChange: (funds: FundToBulkProxy[]) => void;
}

export const BulkProxyPicker = ({
  investments,
  onProxyChange,
  proxyDataByFund,
  onProxyFailedToChange,
}: BulkProxyPickerProps) => {
  const hasExtrapolationFf = useHasFF('extrapolation_ff');
  const { onClose } = useContext(SidePanelOverlay.Context);
  const [selectedProxyType, setSelectedProxyType] = useState<ProxyTypeEnum | undefined>(
    () => getUniqueProxy(investments, proxyDataByFund)?.proxyType,
  );
  const [selectedProxy, setSelectedProxy] = useState<SelectedProxy | null>(() =>
    getUniqueProxy(investments, proxyDataByFund),
  );
  const [isProxiesUpdating, setIsProxiesUpdating] = useState(false);

  const extrapolationToggleProps = useExtrapolateToggle(investments, selectedProxyType, proxyDataByFund);
  const { shouldExtrapolate } = extrapolationToggleProps;
  const supportsCategoryPicker = typeSupportsCategoryPicker(selectedProxyType!);

  const rawInvestmentReturnsRanges = useInvestmentsReturnsRanges(investments, proxyDataByFund);
  const { data: rawProxyReturnsRange } = useProxyReturnsRange(selectedProxy?.id);

  const validationState = useBulkProxyErrorMessage({
    investments,
    rawInvestmentReturnsRanges,
    rawProxyReturnsRange,
    selectedProxy,
    selectedProxyType,
    extrapolate: shouldExtrapolate,
  });

  const onSaveProxy = useCallback(async () => {
    if (validationState.state !== 'ready' || !selectedProxyType) {
      return;
    }
    setIsProxiesUpdating(true);
    const [investmentsWithErrors, investmentsWithoutErrors] = partition(
      investments,
      (investment) => validationState.investmentInfo[investment.id]?.disableSave,
    );
    try {
      await bulkSetProxy(
        investmentsWithoutErrors.map((investment) => ({
          proxyId: selectedProxy?.id,
          fundId: investment.id,
          proxyType: selectedProxyType,
          extrapolate: shouldExtrapolate,
          // TODO(VENN-28259): Ensure numLags can be modified by the user for single proxy workflow
          numLags: validationState.investmentInfo[investment.id]?.suggestedLags ?? 0,
        })),
      );
      onProxyChange(investmentsWithoutErrors.map(({ id }) => id));
      Notifications.notify(
        `${investmentsWithoutErrors.length} proxies updated successfully.`,
        NotificationType.SUCCESS,
      );
      onProxyFailedToChange(investmentsWithErrors);
    } catch (e) {
      onProxyFailedToChange(investments);
      Notifications.notify('Failed to update proxies.', NotificationType.ERROR);
    } finally {
      setIsProxiesUpdating(false);
      onClose();
    }
  }, [
    investments,
    onProxyChange,
    onProxyFailedToChange,
    onClose,
    validationState,
    selectedProxyType,
    shouldExtrapolate,
    selectedProxy,
  ]);

  const proceedMessage = useMemo(() => {
    const validInvestments =
      validationState.state === 'ready'
        ? investments.filter((investment) => !validationState.investmentInfo[investment.id]?.disableSave).length
        : investments.length;
    if (validInvestments !== investments.length) {
      return `Apply proxy to ${validInvestments} of ${investments.length} investments`;
    }
    return 'Apply proxy';
  }, [validationState, investments]);

  return (
    <>
      {isProxiesUpdating && (
        <div className="absolute z-intercom-front-2 top-0 left-0 size-full flex place-items-center bg-white bg-opacity-30 opacity-0 animate-[fade-in-opacity_1s_ease-in-out_1s_1_normal_forwards]">
          <Loading pageLoader />
        </div>
      )}
      <SidePanelOverlay.Header
        title="Proxy investments"
        subtitle={
          <>
            Proxies will be applied across your entire workspace.{' '}
            <LinkStyle>
              <a className="text-venn-dark-blue" href={PROXY_FAQ_HREF} target="_blank" rel="noopener noreferrer">
                Learn more
              </a>
            </LinkStyle>
          </>
        }
      />
      <StyledSidePanelBody>
        <Body>
          {investments.length === 1 && (
            <SingleInvestmentInfo investment={investments[0]} proxyData={proxyDataByFund[investments[0].id]} />
          )}

          <div className="flex flex-row items-baseline gap-6">
            <ProxyTypeOptions
              investments={investments}
              rawInvestmentRanges={rawInvestmentReturnsRanges}
              selectedProxyRange={rawProxyReturnsRange}
              selectedProxyType={selectedProxyType}
              onSelectProxyType={setSelectedProxyType}
              showInvalidTypes
            />
            {hasExtrapolationFf && <ExtrapolationToggle {...extrapolationToggleProps} />}
            {!!selectedProxyType && typeSupportsDesmooth(selectedProxyType) && (
              <div className="flex flex-shrink flex-col gap-2 text-[14px]">
                <span className="font-bold">Desmoothing Lag</span>
                <span className="text-venn-grey-80 text-balance leading-normal">
                  Venn will automatically calculate a lag for each investment once a proxy is chosen. View applied lags
                  on the Portfolio Management page. To apply custom lags, proxy one investment at a time.{' '}
                  <a
                    className="text-venn-dark-blue font-bold"
                    href={DESMOOTHING_HELP_HREF}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                </span>
              </div>
            )}
          </div>
          <SearchLabel>Select an investment to use as a proxy:</SearchLabel>
        </Body>

        {supportsCategoryPicker ? (
          <BulkCategoryOptions
            investments={investments}
            proxyType={selectedProxyType}
            selectedProxy={selectedProxy}
            setSelectedProxy={setSelectedProxy}
            rawInvestmentReturnsRanges={rawInvestmentReturnsRanges}
            selectedProxyType={selectedProxyType}
          />
        ) : (
          <div className="mt-2 p-3 border border-solid rounded border-venn-grey-30">
            <BulkProxySearch
              selectedProxy={selectedProxy}
              setSelectedProxy={setSelectedProxy}
              rawInvestmentReturnsRanges={rawInvestmentReturnsRanges}
              selectedProxyType={selectedProxyType}
              investments={investments}
              shouldExtrapolate={shouldExtrapolate}
              autoProxyEnabled
            />
          </div>
        )}
        <ProxySummaryTable
          investments={investments}
          validationState={validationState}
          proxyDataByFund={proxyDataByFund}
        />
      </StyledSidePanelBody>
      <SidePanelOverlay.Footer
        cancelLabel={
          <div className="flex items-center gap-1.5 text-[12px]">
            <Icon type="angle-left" /> BACK
          </div>
        }
        onCancel={isProxiesUpdating ? undefined : onClose}
        onPrimaryClick={onSaveProxy}
        primaryLabel={proceedMessage}
        primaryDisabled={validationState.state === 'loading' || !!validationState.error || isProxiesUpdating}
        fixed
      />
    </>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SearchLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const StyledSidePanelBody = styled(SidePanelOverlay.Body)`
  margin-bottom: 80px;
`;

function getUniqueProxy(investments: FundToBulkProxy[], proxyData: Record<string, DetailedProxyMetadata | undefined>) {
  const proxyInfo = proxyData[investments[0].id];
  const id = proxyInfo?.proxyId;
  const name = proxyInfo?.proxyName;
  const proxyType = proxyInfo?.proxyType;
  return id &&
    name &&
    investments.every(
      (investment) => proxyData[investment.id]?.proxyId === id && proxyData[investment.id]?.proxyType === proxyType,
    )
    ? {
        id,
        name,
        proxyType,
      }
    : null;
}
